const curry = <A extends any[], R>(fn: (...args: A) => R) => (...args: (([]) | A)[]) => (args.length >= fn.length
  ? fn(...(args as A))
  : (...exceptedArgs: A) => curry(fn)(...args, ...exceptedArgs));

export const withDecorator: (...innerArgs) => any = curry(<T,>(deco: () => void, fn: (args: T) => void) => {
  return (args) => {
    deco();
    fn(args);
  };
});
