import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dialog } from 'sw-ui';

import styles from '../styles/settings.module.css';

import { AIRLINE_PROVIDERS, PROVIDER_OFF } from '../../../constants/setting';
import AjaxButton from '../../../components/ajaxButton';

const LABELS = {
  CONTROL_PROVIDERS: 'Управление поставщиками',
  OFF_PROVIDERS: 'Отключаем поставщика',
  CHOOSE_PROVIDERS: 'Укажите поставщика от имени которого следует теперь оформлять услуги',
};

class RenderControl extends Component {
  static propTypes = {
    businessService: PropTypes.object.isRequired,
    providersObject: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      openDialogProvider: false,
      providerName: '',
    };
  }

  handleOpenDialogProvider = (name, value) => () => {
    const { providersObject } = this.props;

    if (name === value) {
      if (PROVIDER_OFF.includes(name)) {
        providersObject[name] = AIRLINE_PROVIDERS.OFF;
      } else {
        this.setState({
          openDialogProvider: true,
          providerName: name,
        });
      }
    }

    if (name !== value) {
      providersObject[name] = name;
    }

    this.setState({ providersObject });

    this.props.businessService.setAirlineAggregationProviders(providersObject);
  };

  handlerChangeProvider = (providerName, name) => () => {
    const { providersObject } = this.props;

    providersObject[name] = providerName;

    this.setState({
      openDialogProvider: false,
      providersObject,
    });

    this.props.businessService.setAirlineAggregationProviders(providersObject);
  };

  handleCloseDialogProvider = () => {
    this.setState({ openDialogProvider: false });
  };

  renderProviderButton = (providerName) => {
    const { waitResClearCache } = this.state;

    const providersButton = (
      <div className={ styles['button-provider'] }>
        <AjaxButton
          label={ AIRLINE_PROVIDERS.AMADEUS }
          loading={ waitResClearCache }
          onClick={ this.handlerChangeProvider(AIRLINE_PROVIDERS.AMADEUS, providerName) }
        />
      </div>
    );

    return (
      <div className={ styles['button-row'] }>
        { providersButton }
        <div className={ styles['button-provider'] }>
          <AjaxButton
            label={ AIRLINE_PROVIDERS.MIXVEL }
            loading={ waitResClearCache }
            onClick={ this.handlerChangeProvider(AIRLINE_PROVIDERS.MIXVEL, providerName) }
          />
        </div>
        <div className={ styles['button-provider'] }>
          <AjaxButton
            label={ AIRLINE_PROVIDERS.SIRENA }
            loading={ waitResClearCache }
            onClick={ this.handlerChangeProvider(AIRLINE_PROVIDERS.SIRENA, providerName) }
          />
        </div>
        <AjaxButton
          label={ AIRLINE_PROVIDERS.OFF }
          theme='flat'
          loading={ waitResClearCache }
          onClick={ this.handlerChangeProvider(AIRLINE_PROVIDERS.OFF, providerName) }
        />
      </div>
    );
  };

  renderDialogProvider = () => {
    const { openDialogProvider, providerName } = this.state;

    return openDialogProvider && (
      <Dialog onClick={ this.handleCloseDialogProvider } width='600px'>
        <button className={ styles['button-close'] } onClick={ this.handleCloseDialogProvider }>
          <i className='material-icons'>close</i>
        </button>
        <div className={ styles['form-provider'] }>
          <span>
            { LABELS.OFF_PROVIDERS }
            { ' ' }
            { providerName }
            { ' ' }
            <br />
            { ' ' }
            { LABELS.CHOOSE_PROVIDERS }
          </span>
        </div>
        { this.renderProviderButton(providerName) }
      </Dialog>
    );
  };

  render() {
    const airlineProviders = Object.entries(this.props.providersObject).map(([name, value], id) => {
      const modifiedProvider = value !== name
        && (
          <span className={ styles['control-title'] }>
            (
            { value }
            )
          </span>
        );

      return (
        <div className={ styles.control } key={ id }>
          <span className={ styles['control-title'] }>{ name }</span>
          <Checkbox
            value={ value === name }
            className={ styles['control-checkbox'] }
            onChange={ this.handleOpenDialogProvider(name, value) }
          >
            { modifiedProvider }
          </Checkbox>
        </div>
      );
    });

    return (
      <div className={ styles['scheme-aggregation'] }>
        <div className={ styles['setup-title'] }>
          { LABELS.CONTROL_PROVIDERS }
        </div>
        { airlineProviders }
        { this.renderDialogProvider() }
      </div>
    );
  }
}

export default RenderControl;
