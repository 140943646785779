import {
  ReactNode, useContext, useEffect, useState,
} from 'react';
import {
  Input, Loading, Select,
} from 'sw-ui';
import { observer } from 'mobx-react-lite';

import AjaxButton from '../../../../components/ajaxButton';

import COMPONENTS from '../../../../bi/constants/components';

import { WarningStates } from './types';
import {
  BookingSettingsVariants, Notifications, withDecorator,
} from './lib';
import { SettingsContext, SettingsContextModel } from '../../context';

import styles from './styles.module.scss';

const LABELS = {
  TITLE: 'Настройки бронирования',
  SUB_TITLES: {
    DESCRIPTION:
      'Текст на русском, который клиент видит при отключенном бронировании',
    DESCRIPTION_EN:
      'Текст на английском, который клиент видит при отключенном бронировании',
    DISABLE_EVERY_N: 'Выберите процентное соотношение разрешенных бронирований',
  },
  BUTTONS: {
    CHANGE_BUTTON: 'Изменить',
    CONFIRM_BUTTON: 'Подтвердить',
    RESET_BUTTON: 'Сбросить настройки',
  },
};

const {
  INPUT: { TYPE: { TEXT } },
  SELECT: { THEME: { BORDER } },
} = COMPONENTS;

export const BookingSettings = observer((): ReactNode => {
  const {
    bookingService: {
      resetBookingSettings,
      setBookingSettings,
      loadBookingSettings,
      sendBookingSettings,
      bookingStore: {
        loading,
        bookingSettings,
      },
    },
    notificationService: { send },
  } = useContext<SettingsContextModel>(SettingsContext);

  const [warningState, setWarningState] = useState<WarningStates>(WarningStates.PREPARE);

  useEffect(() => {
    loadBookingSettings();
  }, []);

  const withPrepare = withDecorator(() => setWarningState(WarningStates.PREPARE));

  const handleChangeDescription = withPrepare((value: string): void => setBookingSettings({
    ...bookingSettings!,
    description: value,
  }));

  const handleChangeDescriptionEn = withPrepare((value: string): void => setBookingSettings({
    ...bookingSettings!,
    descriptionEn: value,
  }));

  const handleChangeSelectedValue = withPrepare(({ value }: { value: number }): void => setBookingSettings({
    ...bookingSettings!,
    disableEveryN: value,
  }));

  const handleReset = () => resetBookingSettings(
    () => send(Notifications.success),
    () => send(Notifications.failure),
  );

  const handlePreConfirm = () => {
    setWarningState(WarningStates.CONFIRM);
    send(Notifications.warning);
  };

  const handleConfirmChanges = async () => {
    if (warningState === WarningStates.PREPARE) {
      handlePreConfirm();

      return;
    }

    await sendBookingSettings(
      () => send(Notifications.success),
      () => send(Notifications.failure),
    );
  };

  const renderConfirmButton = () => {
    const label = warningState === WarningStates.PREPARE
      ? LABELS.BUTTONS.CHANGE_BUTTON
      : LABELS.BUTTONS.CONFIRM_BUTTON;

    return <AjaxButton onClick={ () => handleConfirmChanges() } label={ label } />;
  };

  const renderBookingSettings = () => {
    if (loading) {
      return <Loading />;
    }

    return (
      <div className={ `${styles.wrapper} ${styles.col}` }>
        <div className={ styles.title }>{ LABELS.TITLE }</div>
        <div className={ styles.col }>
          <div className={ styles.subTitle }>{ LABELS.SUB_TITLES.DISABLE_EVERY_N }</div>
          <Select
            value={ bookingSettings?.disableEveryN }
            items={ BookingSettingsVariants }
            onChange={ handleChangeSelectedValue }
            theme={ BORDER }
          />
          <div className={ styles.subTitle }>
            { LABELS.SUB_TITLES.DESCRIPTION }
          </div>
          <Input
            value={ bookingSettings?.description }
            type={ TEXT }
            onChange={ handleChangeDescription }
          />
          <div className={ styles.subTitle }>
            { LABELS.SUB_TITLES.DESCRIPTION_EN }
          </div>
          <Input
            value={ bookingSettings?.descriptionEn }
            type={ TEXT }
            onChange={ handleChangeDescriptionEn }
          />
        </div>
        <div className={ styles.buttons }>
          { renderConfirmButton() }
          <AjaxButton
            onClick={ () => handleReset() }
            label={ LABELS.BUTTONS.RESET_BUTTON }
          />
        </div>
      </div>
    );
  };

  return renderBookingSettings();
});
