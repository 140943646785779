import { useContext } from 'react';
import { createRoot } from 'react-dom/client';

import { SettingsComponents } from './components/SettingsComponent';

import { SettingsContext, SettingsContextModel } from './context';

const Component = () => {
  const opts = useContext<SettingsContextModel>(SettingsContext);

  return (
    <SettingsComponents { ...opts } />
  );
};

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <SettingsContext.Provider
      value={ {
        bookingService: opts.bookingService,
        businessService: opts.businessService,
        companiesService: opts.companiesService,
        featureFlagsService: opts.featureFlagsService,
        hotelWarningService: opts.hotelWarningService,
        taxiWarningService: opts.taxiWarningService,
        transferWarningService: opts.transferWarningService,
        tripService: opts.tripService,
        vipHallWarningService: opts.vipHallWarningService,
        notificationService: opts.notificationsService,
      } }
    >
      <Component />
    </SettingsContext.Provider>,
  );

  return root;
};

export default renderComponents;
