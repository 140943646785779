import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, Select, Tooltip,
} from 'sw-ui';

import AjaxButton from '../../../../components/ajaxButton';
import { ControlHotelsProviders } from '../ControlHotelsProviders';
import { Warning } from '../Warning';
import { BookingSettings } from '../BookingSettings';
import RenderControl from '../renderContol';

import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/settings.module.css';

const LABELS = {
  ADD_TEMPLATE: 'Добавить заглушку',
  HOTELS: 'Отели',
  CUSTOM: 'Сопутствующие услуги',
  TAXI: 'Такси',
  TRANSFERS: 'Трансферы',
  VIP_HALL: 'Вип-Залы',
  STUB: 'Заглушка',
  SQUEEZE_ALL_TRIPS: 'Сжать все поездки',
  SUM_ORDERS: 'ПОСЧИТАТЬ ЗАКАЗЫ',
  WARNINGS: { DONT_CLICK: 'Не нажимайте лишний раз, пожалуйста!' },
};

const {
  BUTTON: { THEME: { FLAT } },
  TOOLTIP: { POSITION: { TOP } },
} = COMPONENTS;

export class SettingsComponents extends Component {
  static propTypes = {
    companiesService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    businessService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    hotelWarningService: PropTypes.object.isRequired,
    taxiWarningService: PropTypes.object.isRequired,
    transferWarningService: PropTypes.object.isRequired,
    vipHallWarningService: PropTypes.object.isRequired,
    bookingService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();
    const {
      providersObject, schemeSelectList, schemeSelectedValue,
    } = props.businessService.get();

    this.state = {
      openDialog: false,
      openDialogScheme: false,
      waitResCountTrip: false,
      waitResClearCache: false,
      showHotelWarningTemplate: false,
      showTaxiWarningTemplate: false,
      showTransferWarningTemplate: false,
      showVipHallWarningTemplate: false,
      providerName: '',
      schemeSelectList,
      schemeSelectedValue,
      providersObject,
    };
  }

  componentDidMount() {
    const {
      businessService,
      hotelWarningService,
      taxiWarningService,
      transferWarningService,
      vipHallWarningService,
    } = this.props;

    this.unsubscribe = businessService.subscribe(this.updateState);
    this.unsubscribeHotel = hotelWarningService.subscribe(this.updateState);
    this.unsubscribeTaxi = taxiWarningService.subscribe(this.updateState);
    this.unsubscribeTransfer = transferWarningService.subscribe(this.updateState);
    this.unsubscribeVipHall = vipHallWarningService.subscribe(this.updateState);

    businessService.loadSettings();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = (state) => this.setState({ ...state });

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      waitResClearCache: false,
    });
  };

  handlerClearCacheCompanies = () => {
    const clearCache = () => {
      this.props.companiesService.clearCacheCompanies()
        .then(() => this.handleCloseDialog())
        .catch(() => this.handleCloseDialog());
    };

    this.setState({ waitResClearCache: true }, clearCache);
  };

  handlerCountTrip = () => {
    const countTrip = () => {
      this.props.tripService.countTrip()
        .then(() => this.setState({ waitResCountTrip: false }))
        .catch(() => this.setState({ waitResCountTrip: false }));
    };

    this.setState({ waitResCountTrip: true }, countTrip);
  };

  handleChangeSelect = ({ value }) => this.setState({ schemeSelectedValue: value });

  handleClickScheme = () => {
    this.props.businessService.setAirlineAggregation({ name: this.state.schemeSelectedValue })
      .then(() => this.setState({ openDialogScheme: true }));
  };

  handleShowHotelWarningTemplate = () => {
    this.setState((prevState) => ({ showHotelWarningTemplate: !prevState.showHotelWarningTemplate }));
  };

  handleShowTaxiWarningTemplate = () => {
    this.setState((prevState) => ({ showTaxiWarningTemplate: !prevState.showTaxiWarningTemplate }));
  };

  handleShowTransferWarningTemplate = () => {
    this.setState((prevState) => ({ showTransferWarningTemplate: !prevState.showTransferWarningTemplate }));
  };

  handleShowVipHallWarningTemplate = () => {
    this.setState((prevState) => ({ showVipHallWarningTemplate: !prevState.showVipHallWarningTemplate }));
  };

  handleCloseDialogScheme = () => this.setState({ openDialogScheme: false });

  renderScheme = () => {
    const { schemeSelectList, schemeSelectedValue } = this.state;
    if (schemeSelectList.length !== 0) {
      const selectList = schemeSelectList.map((item) => (
        item
          ? ({
            value: item,
            label: item,
          })
          : ({
            value: '',
            label: 'По умолчанию',
          })
      ));

      return (
        <div className={ styles['scheme-aggregation'] }>
          <div className={ styles['setup-title'] }>
            Установить схему агрегации
          </div>
          <div className={ styles['scheme-wrap'] }>
            <Select
              value={ schemeSelectedValue }
              field='Schemes'
              items={ selectList }
              onChange={ this.handleChangeSelect }
            />
            <div className={ `${styles.row} ${styles['setup-wrap']}` }>
              <AjaxButton
                label='Установить схему агрегации'
                onClick={ this.handleClickScheme }
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  renderDialogSchemeHtml = () => (this.state.openDialogScheme
    ? (
      <Dialog onClick={ this.handleCloseDialogScheme }>
        <div className={ styles['form-cache'] }>
          <span>
            Схема агрегации успешно изменена на
            { ' ' }
            { this.state.schemeSelectedValue }
          </span>
          <div className={ `${styles.row} ${styles['row-cache']}` }>
            <Button
              label='Ок'
              theme='flat'
              onClick={ this.handleCloseDialogScheme }
            />
          </div>
        </div>
      </Dialog>
    )
    : null
  );

  renderDialogCacheHtml = () => (this.state.openDialog
    ? (
      <Dialog onClick={ this.handleCloseDialog } width='400px'>
        <div className={ styles['form-cache'] }>
          <span>
            Вы уверены, что хотите сжать все поездки?
            <br />
            (имейте ввиду, что запрос обычно длится дольше 10 минут,
            т.е. превышает допустимый лимит установленный браузером на запросы)
          </span>
          <div className={ `${styles.row} ${styles['row-cache']}` }>
            <Button
              label='Отменить'
              theme='flat'
              onClick={ this.handleCloseDialog }
            />
            <AjaxButton
              label='Подтвердить'
              loading={ this.state.waitResClearCache }
              onClick={ this.handlerClearCacheCompanies }
            />
          </div>
        </div>
      </Dialog>
    )
    : null
  );

  renderHotelsSettings = () => {
    const {
      hotelsProvidersObject,
      showHotelWarningTemplate,
    } = this.state;
    const {
      featureFlagsService, businessService, hotelWarningService,
    } = this.props;

    const { HotelProvidersSwitch = false, CanManageHotelTemplates = false } = featureFlagsService.get();

    const showControlHotelsProviders = hotelsProvidersObject !== null
      && hotelsProvidersObject !== undefined
      && HotelProvidersSwitch;

    const label = showControlHotelsProviders || CanManageHotelTemplates
      ? (
        <h3>{ LABELS.HOTELS }</h3>
      )
      : null;
    const hotelsControlHtml = showControlHotelsProviders
      ? (
        <ControlHotelsProviders
          businessService={ businessService }
          providersObject={ hotelsProvidersObject }
        />
      )
      : null;
    const manageTemplatesHotelsHtml = CanManageHotelTemplates && !showHotelWarningTemplate
      ? (
        <div>
          <h4>{ LABELS.STUB }</h4>
          <div className={ `${styles.row} ${styles['setup-wrap']}` }>
            <AjaxButton
              label={ LABELS.ADD_TEMPLATE }
              onClick={ this.handleShowHotelWarningTemplate }
            />
          </div>
        </div>
      )
      : null;

    return (
      <div>
        { label }
        { hotelsControlHtml }
        { manageTemplatesHotelsHtml }
        <Warning
          warningService={ hotelWarningService }
          showWarningTemplate={ showHotelWarningTemplate }
          onShowWarningTemplate={ this.handleShowHotelWarningTemplate }
        />
      </div>
    );
  };

  renderCustomSettings = () => {
    const {
      showTaxiWarningTemplate, showTransferWarningTemplate, showVipHallWarningTemplate,
    } = this.state;
    const {
      featureFlagsService, taxiWarningService, transferWarningService, vipHallWarningService,
    } = this.props;

    const { CanManageCustomsTemplates = false } = featureFlagsService.get();

    if (!CanManageCustomsTemplates) return null;

    const manageTemplatesTaxiHtml = (
      <div>
        <h4>
          { LABELS.STUB }
          { ' ' }
          -
          { ' ' }
          { LABELS.TAXI }
        </h4>
        <div className={ `${styles.row} ${styles['setup-wrap']}` }>
          <AjaxButton
            label={ LABELS.ADD_TEMPLATE }
            onClick={ this.handleShowTaxiWarningTemplate }
          />
        </div>
      </div>
    );
    const manageTemplatesTransferHtml = (
      <div>
        <h4>
          { LABELS.STUB }
          { ' ' }
          -
          { ' ' }
          { LABELS.TRANSFERS }
        </h4>
        <div className={ `${styles.row} ${styles['setup-wrap']}` }>
          <AjaxButton
            label={ LABELS.ADD_TEMPLATE }
            onClick={ this.handleShowTransferWarningTemplate }
          />
        </div>
      </div>
    );
    const manageTemplatesVipHallHtml = (
      <div>
        <h4>
          { LABELS.STUB }
          { ' ' }
          -
          { ' ' }
          { LABELS.VIP_HALL }
        </h4>
        <div className={ `${styles.row} ${styles['setup-wrap']}` }>
          <AjaxButton
            label={ LABELS.ADD_TEMPLATE }
            onClick={ this.handleShowVipHallWarningTemplate }
          />
        </div>
      </div>
    );

    return (
      <div>
        <h3>{ LABELS.CUSTOM }</h3>
        { manageTemplatesTaxiHtml }
        <Warning
          warningService={ taxiWarningService }
          showWarningTemplate={ showTaxiWarningTemplate }
          onShowWarningTemplate={ this.handleShowTaxiWarningTemplate }
        />
        { manageTemplatesTransferHtml }
        <Warning
          warningService={ transferWarningService }
          showWarningTemplate={ showTransferWarningTemplate }
          onShowWarningTemplate={ this.handleShowTransferWarningTemplate }
        />
        { manageTemplatesVipHallHtml }
        <Warning
          warningService={ vipHallWarningService }
          showWarningTemplate={ showVipHallWarningTemplate }
          onShowWarningTemplate={ this.handleShowVipHallWarningTemplate }
        />
      </div>
    );
  };

  renderBookingSettings = () => {
    const CanSetBookingSettings = this.props.featureFlagsService.getBookingChange();

    if (!CanSetBookingSettings) {
      return null;
    }

    return (
      <BookingSettings />
    );
  };

  render() {
    const { waitResCountTrip, providersObject } = this.state;
    const { businessService } = this.props;
    const renderControl = (providersObject !== null && providersObject !== undefined)
      && <RenderControl
        businessService={ businessService }
        providersObject={ providersObject }
        renderProviderButton={ this.renderProviderButton }
      />;

    return (
      <div className={ `${styles.row} ${styles.gap16}` }>
        <div className={ styles.wrap }>
          <div className={ styles['setup-title'] }>
            { LABELS.SUM_ORDERS }
          </div>
          <div className={ styles.main }>
            <AjaxButton
              label={ LABELS.SUM_ORDERS }
              loading={ waitResCountTrip }
              theme={ FLAT }
              onClick={ this.handlerCountTrip }
            />
          </div>
          { renderControl }
          { this.renderScheme() }
          { this.renderHotelsSettings() }
          { this.renderCustomSettings() }
          <div className={ styles['setup-title'] }>
            { LABELS.SQUEEZE_ALL_TRIPS }
          </div>
          <div className={ styles.compress }>
            <div className='sw-tooltip-wrapper'>
              <Button
                label={ LABELS.SQUEEZE_ALL_TRIPS.toUpperCase() }
                theme={ FLAT }
                onClick={ this.handleOpenDialog }
              />
              <Tooltip
                position={ TOP }
              >
                { LABELS.WARNINGS.DONT_CLICK }
              </Tooltip>
            </div>
          </div>
          { this.renderDialogSchemeHtml() }
          { this.renderDialogCacheHtml() }
        </div>
        { this.renderBookingSettings() }
      </div>
    );
  }
}
