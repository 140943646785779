import { BookingSettingsModel } from '../types';

const NOTIFICATIONS = {
  WARN: 'Вы уверены, что хотите поменять настройки бронирования?',
  SUCCESS: 'Настройки бронирования успешно изменены',
  ERR: 'Не удалось обновить настройки бронирования',
};

const LABELS_BOOKING_SETTING_VARIANTS = {
  ALLOWED: '100% (все бронирования разрешены)',
  SECOND: '50% (каждое 2-е бронирование разрешено)',
  FOURTH: '25% (каждое 4-е бронирование разрешено)',
  EIGHTH: '12,5% (каждое 8-е бронирование разрешено)',
  FORBIDDEN: '0%  (бронирование отключено полностью)',
};

export const DefaultSettings: BookingSettingsModel = {
  disableEveryN: 1,
  description: '',
  descriptionEn: '',
};

type BookingSettingsVariantModel = {
  value: string | number,
  label: string,
};

enum NotificationLevels {
  WARN = 'warning',
  SUCCESS = 'success',
  FAIL = 'failure',
}

type NotificationModel = {
  message: Readonly<string>,
  level: Readonly<NotificationLevels>,
};

export const BookingSettingsVariants: Array<BookingSettingsVariantModel> = [
  { value: 1, label: LABELS_BOOKING_SETTING_VARIANTS.ALLOWED },
  { value: 2, label: LABELS_BOOKING_SETTING_VARIANTS.SECOND },
  { value: 4, label: LABELS_BOOKING_SETTING_VARIANTS.FOURTH },
  { value: 8, label: LABELS_BOOKING_SETTING_VARIANTS.EIGHTH },
  { value: 0, label: LABELS_BOOKING_SETTING_VARIANTS.FORBIDDEN },
];

export const Notifications: Readonly<
  Record<NotificationLevels, NotificationModel>
> = {
  [NotificationLevels.WARN]: {
    message: NOTIFICATIONS.WARN,
    level: NotificationLevels.WARN,
  },
  [NotificationLevels.FAIL]: {
    message: NOTIFICATIONS.ERR,
    level: NotificationLevels.FAIL,
  },
  [NotificationLevels.SUCCESS]: {
    message: NOTIFICATIONS.SUCCESS,
    level: NotificationLevels.SUCCESS,
  },
};
